<template>
  <v-card
    class="rounded-lg"
    >
    <v-card-title>
      Detalle notificación
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="$emit('close')"
        >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      class="pa-0"
      v-if="like"
      >
      <template
        v-if="like.likeableType == 'Social::Post'"
        >
        <post-card
          tile
          outlined
          :idx="0"
          :postId="like.likeableId"
          ></post-card>
      </template>
      <template
        v-if="like.likeableType == 'Social::Comment'"
        >
        <comment
          :id="like.likeableId"
          ></comment>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { Like } from '@/graphql/queries/posts'

const Comment = () => import('./Comment')
const PostCard = () => import('@/components/posts/Card')

export default {
  data: () => ({
    like: null,
  }),

  props: {
    id: {
      required: true,
      type: String | Number
    }
  },

  created () {
    this.fetchLike ()
  },

  methods: {
    fetchLike () {
      this.$apollo.query({
        query: Like,
        variables: {
          id: this.id
        }
      }).then ( res => {
        this.like = res.data.like
      })
    },
  },

  components: { Comment, PostCard }
}
</script>
